import React from "react";
import './wpl-two-button-input.css'; 

export default function WplTwoButtonInput({
  title,
  options,
  value,
  onChanged,
  style,
  className,
  ...rest
}) {
  return (
    <div className={`wpl-two-button-input ${className ? className : ''}`} style={style}>
      <p className="title-label">{title}</p>
      <div className="btn-group">
        {options.map((option) => (
          <button
            key={option}
            className={value === option ? 'active' : ''}
            onClick={() => onChanged(option)}
            {...rest}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
