import React, {useEffect, useMemo, useState} from 'react';
import './lightning-parent-page.css'
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import BurgerMenu from "../../components/burger-menu/BurgerMenu";
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";
import home from '../../assets/img/home-24px.svg'
import face from '../../assets/img/face-24px.svg'
import power from '../../assets/img/power_settings_new-24px.svg'
import {
    doFetchWindfarmLightningTurbines,
    selectAllLightningTurbines, selectLightningWindfarms, selectTurbinesDirty,
    selectTurbinesLoading
} from "../../flow/lightningFlow";
import {useDispatch, useSelector} from "react-redux";
import {windfarmAPI} from "../../api";

export default function LightningParentPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const turbinesLoading = useSelector(selectTurbinesLoading);
    const turbines = useSelector(selectAllLightningTurbines)
    const windfarms = useSelector(selectLightningWindfarms)

    const [windfarm, setWindfarm] = useState(null);

    const turbinesDirty = useSelector(selectTurbinesDirty)

    let {windfarm_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }

    useEffect(() => {
        if (!windfarm_id) {
            setWindfarm(null);
            return;
        }
        if (windfarm && windfarm.id === windfarm_id) return;
        if (!windfarms || !windfarms.length) {
            windfarmAPI.getById(windfarm_id).then(setWindfarm)
        }
        setWindfarm(windfarms.find(wf => wf.id === parseInt(windfarm_id)))
    }, [windfarm, windfarms, windfarm_id]);

    useEffect(() => {
        if (!turbinesDirty && (turbinesLoading || (turbines && turbines.length > 0))) return;
        dispatch(doFetchWindfarmLightningTurbines());
    }, [dispatch, turbinesLoading, turbines, turbinesDirty]);

    return (<div className='lightning-parent-page'>
        <div className='lightning-header-menu'>
            <img className='logo' alt='Wind Power Lab' src={logo} style={{
                height: 40,
                padding: 10,
                width: 'auto',
                cursor: 'pointer',
            }} onClick={() => {navigate('/')}} />
            <h2 className='lassie-link' onClick={_ => navigate('/lassie')}>LASSIE</h2>
            <div className='grower' />

            <BurgerMenu menuItems={['Home', 'Support & FAQ', 'Log out']}
                        menuLinks={['', 'support', '/logout']}
                        images={[home, face, power]}
            />
        </div>
        <div className='breadcrumb'>
            <Link to={`/lassie`}>Portfolio</Link>
            {windfarm && <label style={{padding: '0 10px'}}>{'>'}</label>}
            {windfarm && <Link to={`/lassie/windfarm/${windfarm_id}`}>{windfarm.name}</Link>}
            <div className='grower' />

            <Link to={`historic-lightning${windfarm ? `/windfarm/${windfarm.id}` : ''}`}>Historic Lightning Analysis</Link>
            <Link to={`report${windfarm ? `/windfarm/${windfarm.id}` : ''}`}>Lightning Report</Link>
            <Link to={`dashboard${windfarm ? `/windfarm/${windfarm.id}` : ''}`}>Dashboard</Link>
            <Link to={'dtn-weather-sentry'}>DTN Weather Sentry</Link>
        </div>
        <div className='content-wrapper'>
            <Outlet/>
        </div>
    </div>)
}
