import React, {useEffect, useMemo, useState} from 'react';
import './manage-lightning-risk.css'
import {useDispatch} from "react-redux";
import {createUserAction} from "../../../flow/lightningFlow";
import WplButton from "../../../components/wpl-button/WplButton";
import {toPrettyDateStr, toPrettyTimeSince} from "../../../prettyDate";
import TurbineScenarioList from "../turbine-scenario-list/TurbineScenarioList";
import TurbineLightningRuler from "../../../components/turbine-lightning-ruler/TurbineLightningRuler";
import {Link, useParams} from "react-router-dom";
import {lightningAPI} from "../../../api";
import InfoCards from "../../../components/info-card/InfoCard";
import {probabilityToString} from "../probabilityToString";
import WplInput from "../../../components/wpl-input/WplInput";
import ShowLessMore from "../../../components/show-lessmore/ShowLessMore";

export default function ManageLightningRisk({firstClickedTurbine, turbines, collectionTitle}) {
    const [selectedTurbine, setSelectedTurbine] = useState(null);
    const [turbineStatistics, setTurbineStatistics] = useState([]);
    const [loading, setLoading] = useState(false);

    // Filters
    const [riskFilter, setRiskFilter] = useState(null);

    // Turbine activity
    const [latestUserAction, setLatestUserAction] = useState(null);
    const [showTabular, setShowTabular] = useState({});

    const dispatch = useDispatch()

    let {windfarm_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }

    useEffect(() => {
        setLoading(false);
        if (!turbines) return;
        if (!selectedTurbine) {
            if (riskFilter) {
                const fct = turbines.find(tar => probabilityToString(tar.probability_of_atleast_one) === riskFilter)
                if (fct) {
                    setSelectedTurbine(fct)
                    return;
                }
                setSelectedTurbine(null)
                return;
            }
            if (firstClickedTurbine) {
                const fct = turbines.find(tar => tar.id === firstClickedTurbine)
                if (fct) {
                    setSelectedTurbine(fct)
                    return;
                }
            }
            setSelectedTurbine(turbines[0])
        } else {
            const inTar = turbines.find(t => t.id === selectedTurbine.id)
            if (!inTar) {
                setSelectedTurbine(turbines[0])
            }
        }
    }, [turbines, selectedTurbine, firstClickedTurbine, riskFilter]);

    useEffect(() => {
        if (!selectedTurbine) return;
        setTurbineStatistics([{
            format: 'string',
            title: 'loading',
            value: '...'
        }]);
        lightningAPI.getStatistics({turbine_id: selectedTurbine.id}).then(setTurbineStatistics);
        lightningAPI.getLatestUserAction(selectedTurbine.id).then(setLatestUserAction)
        setShowTabular({});
    }, [selectedTurbine]);

    const tarWfGroups = useMemo(() => {
        if (!turbines) return {}
        const final = {}
        turbines.filter(t => !windfarm_id || t.windfarm_id === windfarm_id).forEach(tar => {
            final[tar.windfarm] = [...(final[tar.windfarm] ?? []), tar]
        })
        return final
    }, [turbines, windfarm_id]);

    return (<div className={`manage-lightning-risk`}>
        <div className='outer left'>
            {collectionTitle && <h1 className='collection-title'>{collectionTitle}</h1>}
            <div className='filter-wrapper'>
                <WplInput
                    placeholder='High/Medium risk...'
                    clearable
                    onlySelectableOptions
                    options={['High', 'Medium', 'Low']}
                    value={riskFilter}
                    onChanged={r => {
                        setRiskFilter(r)
                        setSelectedTurbine(null)
                    }}
                />
            </div>
            <div className='turbine-list-scroller'>
                <div className='turbine-list'>
                    {Object.keys(tarWfGroups).map(windfarm_name => {
                        return [
                            <h3 key={windfarm_name}>{windfarm_name}</h3>,
                            ...tarWfGroups[windfarm_name]
                                .filter(tar => !riskFilter || probabilityToString(tar.probability_of_atleast_one) === riskFilter)
                                .map(tar =>
                                    <div
                                        className={`turbine ${selectedTurbine && tar.id === selectedTurbine.id ? 'selected' : ''}`}
                                        key={tar.id}
                                        onClick={_ => setSelectedTurbine(tar)}>
                                        <div className='title-wrapper'>
                                            <h4>{tar.name}</h4>
                                            <label
                                                className={`${probabilityToString(tar.probability_of_atleast_one).split(' ').join('-')}-risk`}>{probabilityToString(tar.probability_of_atleast_one)} risk</label>
                                        </div>
                                        {tar.latest_trigger_date &&
                                            <label>{toPrettyDateStr(new Date(tar.latest_trigger_date))}</label>}
                                    </div>
                                )]
                    })}
                </div>
            </div>
        </div>
        {!selectedTurbine && <div className='outer turbine-details'>
            <h2>No turbine selected.</h2>
        </div>}
        {selectedTurbine && <div className='outer turbine-details'>
            <h2 className='title'>{selectedTurbine.name}</h2>
            <h3 className='subtitle'>{selectedTurbine.windfarm}</h3>
            <TurbineLightningRuler strikes={selectedTurbine.turbine_lightning}/>
            <InfoCards statistics={turbineStatistics}/>
            <TurbineScenarioList turbine={selectedTurbine}>
                {latestUserAction && <div className='scenario'>
                    <h2>Latest user action:</h2>
                    {/*<h3>{latestUserAction.action[0].toUpperCase() + latestUserAction.action.split('_').join(' ').substring(1)}</h3>*/}
                    <div key={latestUserAction.id} className='user-action'>
                        <p>{toPrettyDateStr(new Date(latestUserAction.target_date))}</p>
                        <b>Set status {latestUserAction.action}</b>
                        {latestUserAction.document_id && <p><b>Document</b></p>}
                        {!!latestUserAction.user_comment &&
                            <p className='user-comment'>"{latestUserAction.user_comment}"</p>}
                        <p> {latestUserAction.user_name}</p>
                    </div>
                </div>}
                <div className='scenario'>
                    <h3>Details</h3>
                    <p>To get more information about what happened prior to the latest user action, go to lightning report:</p>
                    <Link to={`/lassie/report/windfarm/${selectedTurbine.windfarm_id}`}>Lightning Report</Link>
                </div>
            </TurbineScenarioList>

            <div className='grower'/>
        </div>}

        <div className='vertical button-wrapper'>
            {selectedTurbine && selectedTurbine.status === 'at_risk' &&
                <WplButton disabled={loading} className='user-action-btn red' value='Ignore Risk' red onClick={_ => {
                    setLoading(true)
                    dispatch(createUserAction({
                        turbine_ids: [selectedTurbine.id],
                        action: 'acceptable_risk',
                        user_comment: ''
                    }))
                }} big style={{fontSize: '1em'}}/>}
            <WplButton disabled={loading} className='user-action-btn' value='Mark for Inspection' onClick={_ => {
                setLoading(true)
                dispatch(createUserAction({
                    turbine_ids: [selectedTurbine.id],
                    action: 'marked_for_inspection',
                    user_comment: ''
                }))
            }} big/>
        </div>
    </div>)
}
