import React from 'react';
import { icons_at_risk, icons_handled, icons_marked_for_inspection, icons_at_risk_ping_sensor, icons_marked_for_inspection_ping_sensor, icons_handled_ping_sensor } from "../ol-map/icons";
import infoIcon from '../../assets/img/map-info.png'
import './map-references.css'

export default function MapReferences({ popoutReferencesOpen, setPopoutReferencesOpen, setQaOpen, setPopoutSettingsOpen, popoutSettingsOpen }) {

    const handleClick = () => {
        setPopoutReferencesOpen(!popoutReferencesOpen)
        if (popoutSettingsOpen) { setPopoutSettingsOpen(false) };
    };

    return (
        <div className='legend'>
            <div className='image-container'>
                {!popoutReferencesOpen && (
                    <div className='images'>
                        {icons_at_risk.High && <img src={icons_at_risk.High} alt='High' title='High' />}
                        {icons_marked_for_inspection && <img src={icons_marked_for_inspection} alt='High' />}
                        {icons_handled && <img src={icons_handled} alt='High' />}
                    </div>
                )}
                <div
                    onClick={handleClick}
                    className={`arrow-icon ${popoutReferencesOpen ? 'open' : 'closed'}`} 
                ></div>
            </div>

            {popoutReferencesOpen && <div>
                <div className='icons-category'>
                    <p>At risk</p>
                    <div className='images'>
                        <img src={icons_at_risk.High} alt='High' title='High' />
                        <img src={icons_at_risk.Medium} alt='Medium' title='Medium' />
                        <img src={icons_at_risk.Low} alt='Low' title='Low' />
                    </div>
                </div>
                <div className='icons-category'>
                    <p>Marked for inspection</p>
                    <div className='images'>
                        <img src={icons_marked_for_inspection} alt='High' />
                    </div>
                </div>
                <div className='icons-category'>
                    <p>Handled</p>
                    <div className='images'>
                        <img src={icons_handled} alt='High' />
                    </div>
                </div>
                <div className='icons-category'>
                    <p>Lightning sensor data</p>
                    <div className='images'>
                        <img src={icons_at_risk_ping_sensor.High} alt='High' title='High' />
                        <img src={icons_at_risk_ping_sensor.Medium} alt='Medium' title='Medium' />
                        <img src={icons_at_risk_ping_sensor.Low} alt='Low' title='Low' />
                    </div>
                    <div className='images'>
                        <img src={icons_marked_for_inspection_ping_sensor} alt='High' />
                        <img src={icons_handled_ping_sensor} alt='High' />
                    </div>
                </div>
                <div className='images'>
                    <img src={infoIcon} alt='info icon' className='info-icon' onClick={() => setQaOpen({ status: true, type: 'references' })} />
                </div>
            </div>}
        </div>
    );
}
