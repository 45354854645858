export default function WeatherSentryPage() {
    return (
        <div>
            <h1>DTN Weather Sentry</h1>
            <div>
                <div>
                    <iframe
                        width="560"
                        height="600"
                        src="https://weather.dtn.com/dtnweather/"
                        title="DTN Weather Sentry"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
